import React from "react";
import ProjectCard from "../components/ProjectCard";

const ProjectGrid = ({ CaseStudiesData, ai }) => {
    if (CaseStudiesData && CaseStudiesData.length > 0) {
        return (
            <section className="project-grid">
                {CaseStudiesData.map((d, i) => (
                    <ProjectCard projectData={d} key={d.id} ai={d.type === "AI"} />
                ))}
            </section>
        );
    } else {
        return <p className="text-p1">No case studies found at this moment!</p>;
    }
};

export default ProjectGrid;
