import React from "react";
import { useState } from "react";
import axios from "axios";
import { navigate } from "gatsby";

const formAPIURL = `${process.env.GATSBY_BLOG_FORM_URL}`;

const EmailUsBlock = ({ title }) => {
    const [isSubmitted, setSubmit] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [validationErrors, setValidationError] = useState("");
    const [formSubmitError, setSubmitError] = useState("");

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (emailValue === "") {
            setValidationError("Email is required");
        } else if (
            !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                emailValue
            )
        ) {
            setValidationError("Invalid Email");
        } else if (
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                emailValue
            ) &&
            emailValue !== ""
        ) {
            setValidationError("");
            setSubmitError("");
            const formdata = emailValue;
            axios
                .post(formAPIURL, formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    setSubmit(false);
                    setEmailValue("");
                    navigate("/contact/thank-you/");
                })
                .catch((err) => {
                    setSubmit(false);
                    setSubmitError("Oops, Something went wrong. Please try again");
                });
        }
    };

    return (
        <>
            <div className="email__text">
                <h2 className="text-h2 text-fw-medium text-primary-clr">
                    {title
                        ? "Get curated insights and fresh ideas delivered to your inbox."
                        : "Stay up-to-date with the latest insights and news from Sedin"}
                </h2>
            </div>
            <div className="email__subscribe flex flex-ai-c">
                <p className="text-p1 text-primary-clr text-fw-medium">
                    Subscribe to email updates
                </p>
                <div>
                    <div className="email__subscribe-field flex flex-ai-c">
                        <form onSubmit={(e) => handleFormSubmit(e)} noValidate autoComplete="off">
                            <input
                                type="text"
                                placeholder="Enter your email"
                                name="email"
                                value={emailValue}
                                onChange={(e) => setEmailValue(e.target.value)}
                                autoComplete="off"
                                required
                                className={`${
                                    validationErrors && isSubmitted
                                        ? "error text-p4 text-fw-regular text-secondary-clr"
                                        : "text-p4 text-fw-regular text-secondary-clr email-input"
                                }`}
                            />
                            <button className="sdn-cta-btn">Subscribe</button>
                        </form>
                    </div>
                    <div className="form-error">{formSubmitError}</div>
                    <div className="error-resp">{validationErrors}</div>
                </div>
            </div>
        </>
    );
};
export default EmailUsBlock;
