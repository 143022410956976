import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ArrowIcon from "./ArrowIcon";

const ProjectCard = ({ projectData, ai }) => {
    return (
        <div
            className="project-card"
            style={{
                background: `#${projectData.colorTheme}`,
            }}
        >
            <Link
                to={
                    ai
                        ? `/ai-chapter/genAI-case-studies/${projectData.slug}`
                        : `/casestudies/${projectData.slug}/`
                }
            >
                <div className={`project-card__wrapper ${projectData.thumbnailTheme}`}>
                    <div className="client">
                        <h3 className="text-h1 text-fw-bold">{projectData.name}</h3>
                    </div>
                    <div className="description">{projectData.description.description}</div>
                    <div className="services">
                        {projectData.services.map((s) => (
                            <span key={s.id}>{s.title}</span>
                        ))}
                    </div>
                    <div className="footer">
                        <div className="division-name">BY {projectData.doneBy.name}</div>
                        <div className="arrow">
                            <ArrowIcon direction="right" theme={projectData.thumbnailTheme} />
                        </div>
                    </div>
                </div>
                {projectData.backgroundType === "media" && (
                    <div className="project-card__background-media">
                        <div className="project-card__background-media-overlay">
                            {projectData.backgroundMedia.file.contentType === "video/mp4" &&
                            !projectData.backgroundMedia.gatsbyImageData ? (
                                <video controls={false} autoPlay muted loop>
                                    <source
                                        src={projectData.backgroundMedia.file.url}
                                        type={projectData.backgroundMedia.file.contentType}
                                    />
                                </video>
                            ) : (
                                <GatsbyImage
                                    image={projectData.backgroundMedia.gatsbyImageData}
                                    alt={projectData.backgroundMedia.title}
                                    loading="lazy"
                                />
                            )}
                        </div>
                    </div>
                )}
            </Link>
        </div>
    );
};

export default ProjectCard;
