import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "@reach/router";

const ResourceBanner = ({ image, title, slug, description, events, eventType, date }) => {
    return (
        <>
            <div className="resource-grid">
                <GatsbyImage className="resource-image" image={image} alt={title} loading="lazy" />

                <div className="resource-item">
                    <h2 className="text-h2 resource-header text-fw-medium gap-2x">
                        {events ? "Featured event" : "Featured content"}
                    </h2>
                    {events ? (
                        <p className="resource-category text-p6  text-fw-medium  text-decor-none">
                            {eventType}
                        </p>
                    ) : (
                        <Link
                            className="resource-category text-p6  text-fw-medium  text-decor-none"
                            to={`/blog/${slug}/`}
                        >
                            Blog
                        </Link>
                    )}
                    <p className="text-p6 resource-date text-fw-medium ">{date}</p>

                    <h3 className="text-h3 resource-title text-fw-medium">{title}</h3>

                    <p className="text-p4 resource-description text-fw-medium gap-2x">
                        {description}
                    </p>
                    {events ? (
                        // <a target="_blank" rel="noreferrer" className="sdn-cta-btn" href={slug}>
                        <a target="_blank" rel="preload" className="sdn-cta-btn" href={slug}>
                            See event details
                        </a>
                    ) : (
                        <Link className="sdn-cta-btn" to={`/blog/${slug}/`}>
                            Read more
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
};

export default ResourceBanner;
