import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo.jsx";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ArrowIcon from "../../components/ArrowIcon";
import ArticleGrid from "../../components/ArticleGrid";
import ProjectGrid from "../../components/ProjectGrid";
import EmailUsBlock from "../../components/EmailUsBlock";
import ResourceBanner from "../../components/ResourceBanner";
import blogImage from "../../images/blog.png";
import caseStudies from "../../images/case-studies.png";
import ogIMage from "../../images/og.png";

const InsightsPage = ({ location }) => {
    const insights = useStaticQuery(graphql`
        {
            contentfulBlogs(
                title: {
                    eq: "Empowering Global Capability Centers: A Decade of Excellence in Tech Partnership "
                }
            ) {
                id
                title
                description {
                    description
                }
                slug
                blogImage {
                    gatsbyImageData(
                        width: 584
                        quality: 100
                        placeholder: BLURRED
                        layout: CONSTRAINED
                        aspectRatio: 1
                    )
                    title
                }
            }
            allContentfulBlogCategory(limit: 3) {
                nodes {
                    categoryType
                    slug
                    colorTheme
                    slug
                    banner {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        title
                    }
                }
            }
            allContentfulBlogs(limit: 3) {
                nodes {
                    title
                    description {
                        description
                    }
                    readTime
                    slug
                    categories {
                        slug
                        categoryType
                    }
                    author
                    blogImage {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        title
                    }
                }
            }
            allContentfulDivisions(limit: 2) {
                nodes {
                    casestudies {
                        id
                        name
                        logo {
                            gatsbyImageData(placeholder: BLURRED, quality: 100)
                            title
                        }
                        services {
                            id
                            title
                        }
                        description {
                            description
                        }
                        doneBy {
                            name
                        }
                        backgroundType
                        backgroundMedia {
                            gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                            title
                            file {
                                contentType
                                url
                            }
                        }
                        colorTheme
                        thumbnailTheme
                        slug
                    }
                }
            }
            allContentfulNews(limit: 3, filter: { featured: { eq: true } }) {
                nodes {
                    logo {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        title
                    }
                    title
                    externalUrl
                    date
                }
            }
        }
    `);
    const insightsFeaturedImg = insights.contentfulBlogs.blogImage.gatsbyImageData;
    const insightsCasestudies = insights.allContentfulDivisions.nodes.map((el) => el.casestudies);

    return (
        <Layout>
            <Seo
                title={" Insights - Sedin"}
                description={
                    "Read insightful blogs on technology, find detailed case studies on our customer-centric solutions, learn about our culture, events and more. Click to start."
                }
                image={`https://sedintechnologies.com${ogIMage}`}
                url={location.href}
            />
            <section className="insights-hero">
                <div className="container container--first-v3 ">
                    <div className="section-tag gap-2x">Insights</div>
                    <h1 className="text-h1 text-clr-primary text-fw-medium ">
                        Explore insights on business, culture and creative technology
                    </h1>
                    {/* <ResourceBanner resourceData={insights.contentfulBlogs} /> */}
                    <ResourceBanner
                        image={insights.contentfulBlogs.blogImage.gatsbyImageData}
                        slug={insights.contentfulBlogs.slug}
                        title={insights.contentfulBlogs.title}
                        description={insights.contentfulBlogs.description.description}
                    />
                </div>
            </section>
            <section className="resource-type">
                <div className="container container--mid-v5">
                    <div className="resource-type__text">
                        <h2 className="text-h2 text-clr-primary text-fw-medium">Topics</h2>
                    </div>
                    <div className="resource-type__grid">
                        {insights.allContentfulBlogCategory.nodes.slice(0, 3).map((category, i) => (
                            <Link
                                to={`/blog/${category?.slug}`}
                                key={i}
                                className="text-decor-none"
                            >
                                <div
                                    className="resource-type__grid-item"
                                    style={{
                                        background: `url(${category?.banner?.gatsbyImageData?.images.fallback.src})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                    }}
                                >
                                    <p className="text-p1 text-decor-none text-fw-medium resource-type__grid-item__title">
                                        {category?.categoryType}
                                    </p>
                                    <div className="arrow">
                                        <ArrowIcon direction="right" theme={"dark"} />
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
            <section className="resources-blog">
                <div className="container container--mid-v4">
                    <div className="resources-blog__text">
                        <h2 className="text-h2 text-clr-primary text-fw-medium">Blog</h2>
                        <Link
                            className="text-p3 text-fw-medium text-decor-none"
                            to="/insights/blog/"
                        >
                            View all
                        </Link>
                    </div>
                    <ArticleGrid featuredArticles={insights.allContentfulBlogs.nodes} />
                </div>
            </section>
            <section className="resource-case-studies">
                <div className="container container--mid-v4">
                    <div className="resource-case-studies__text">
                        <h2 className="text-h2 text-clr-primary text-fw-medium">Case Studies</h2>
                        <Link className="text-p3 text-fw-medium text-decor-none" to="/casestudies">
                            View all
                        </Link>
                    </div>
                    <div>
                        <ProjectGrid CaseStudiesData={insightsCasestudies[1]} />
                    </div>
                </div>
            </section>
            <section className="featured-news">
                <div className="container container--mid-v4">
                    <div className="featured-news__text">
                        <h2 className="text-h2 text-fw-medium text-clr-primary">
                            News and announcements
                        </h2>
                        <Link
                            to="/about/newsroom"
                            className="text-p3 text-fw-medium text-decor-none"
                        >
                            View all
                        </Link>
                    </div>
                    <div className="featured-news__grid">
                        {insights.allContentfulNews.nodes.map((el, i) => (
                            <>
                                <Link
                                    className="featured-news__grid-item text-decor-none text-clr-primary"
                                    to={el.externalUrl}
                                    key={i}
                                >
                                    <div className="logo">
                                        <GatsbyImage
                                            image={el.logo?.gatsbyImageData}
                                            alt={el.title}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="title text-p1 text-clr-primary text-fw-medium">
                                        {el.title}
                                    </div>
                                    <div className="flex flex-jc-sb flex-ai-c">
                                        <div className="text-clr-primary text-p5 text-fw-regulars">
                                            {el.date}
                                        </div>

                                        <div className="arrow">
                                            <ArrowIcon direction="right" theme={"blue"} />
                                        </div>
                                    </div>
                                </Link>
                            </>
                        ))}
                    </div>
                </div>
            </section>
            <section className="resources-category">
                <div className="container container--mid-v6">
                    <div className="resources-category__text text-h2 text-clr-primary text-fw-medium">
                        Browse by category
                    </div>
                    <div className="resources-category__grid">
                        <Link to="/insights/blog/" className="text-decor-none">
                            <div
                                className="resources-category__grid-item"
                                style={{
                                    background: `url(${blogImage})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                }}
                            >
                                <p className="text-p1 text-fw-medium resource-type__grid-item__title">
                                    Blog
                                </p>

                                <div className="arrow">
                                    <ArrowIcon direction="right" theme={"dark"} />
                                </div>
                            </div>
                        </Link>
                        <Link to="/casestudies" className="text-decor-none">
                            <div
                                className="resources-category__grid-item"
                                style={{
                                    background: `url(${caseStudies})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                }}
                            >
                                <p className="text-p1 text-fw-medium resource-type__grid-item__title">
                                    Case studies
                                </p>

                                <div className="arrow">
                                    <ArrowIcon direction="right" theme={"dark"} />
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>

            <section>
                <div className="container container--last-v2">
                    <EmailUsBlock
                        title={"Get curated insights and fresh ideas delivered to your inbox."}
                    />
                </div>
            </section>
        </Layout>
    );
};

export default InsightsPage;
