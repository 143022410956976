import React from "react";

const ArrowIcon = ({ direction, theme, arrowColor = "#0000FF" }) => {
    if (theme && theme === "dark") {
        arrowColor = "#ffffff";
    }
    if (theme && theme === "light") {
        arrowColor = "#000000";
    }
    return (
        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
            <g>
                <g
                    transform={direction === "left" ? "rotate(180 16 16)" : "rotate(0 16 16)"}
                    stroke="null"
                >
                    <rect
                        stroke="null"
                        x="0.5"
                        y="0.5"
                        opacity="0"
                        fill={arrowColor}
                        height="32"
                        width="32"
                    />
                    <path
                        stroke="null"
                        fill={arrowColor}
                        d="m27.34116,15.31509l-7.75,-7.75l-1.36981,1.36981l6.09634,6.09634l-19.94269,0l0,1.9375l19.94269,0l-6.09634,6.09634l1.36981,1.36981l7.75,-7.75a0.96875,0.96875 0 0 0 0,-1.36981z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ArrowIcon;
