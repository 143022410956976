import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useState } from "react";

const ArticleGrid = ({ featuredArticles }) => {
    const [articlePerRow, setArticlePerrow] = useState(9);
    const loadMore = () => {
        setArticlePerrow((preValue) => preValue + 9);
    };

    return (
        <>
            <div className="article__grid">
                {featuredArticles?.slice(0, articlePerRow).map((article, index) => (
                    <div className="article__grid-item" key={index}>
                        <div className="article__grid-item__imge-wrap">
                            <GatsbyImage
                                class="article-img"
                                image={article.blogImage.gatsbyImageData}
                                alt={article.title}
                                loading="lazy"
                            />
                        </div>
                        <div className="article__grid-item__content">
                            <div className="category flex flex-ai-c gap-05x text-clr-primary text-fw-medium text-p6">
                                {article.categories?.map((category, i) => (
                                    <Link
                                        to={`/blog/${category.slug}/`}
                                        key={i}
                                        className="text-decor-none text-clr-primary"
                                    >
                                        {category.categoryType}
                                    </Link>
                                ))}
                            </div>
                            <div className="title">
                                <p className="text-p2  text-clr-primary text-fw-medium">
                                    <Link
                                        className="text-clr-primary text-decor-none"
                                        to={`/blog/${article.slug}/`}
                                    >
                                        {article.title}
                                    </Link>
                                </p>
                            </div>
                            <div className="article__grid-item__info flex flex-ai-c">
                                <p className="text-p5 text-clr-secondary text-fw-regular">
                                    {article.author}
                                </p>
                                <p className="text-p5 text-clr-secondary text-fw-regular">
                                    {article.readTime}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {articlePerRow < featuredArticles?.length && (
                <div className="article-cta-wrap">
                    <p className="load-more-btn text-p4 text-fw-medium" onClick={loadMore}>
                        Load more
                    </p>
                </div>
            )}
        </>
    );
};

export default ArticleGrid;
